import App from "./App";
import ReactDOM from "react-dom/client"


import "../src/styles/style.css"
import "./styles/static-features.css"
import "./styles/listings.css"
import "./styles/home.css"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);



