
import { motion, AnimatePresence } from "framer-motion";
import Map from "../images/Map.png"
import Footer from "../static-features/Footer";

export default function GettingHere()
{
    document.body.style.background = 'rgb(0,0,0)';
    return(
        <div className="getting-here-page">
   
            <motion.div
            initial={{opacity:0}}
            animate={{opacity:1}}
            >

            <div className="getting-here-content-container">
            <h1 className="getting-here-heading">
                GETTING HERE
            </h1>

           

            <p>
            Postcodes for the 4 main sites are : Venue MOT (SE14 5RT),  Avalon Cafe (SE14 5RW), Ormside Projects (SE15 1TR) & Bridgehouse Gardens (SE15 1EP). The nearest train stations are South Bermondsey & Surrey Quays.</p>

            <img src={Map} className="map-image"/>
            </div>

            <Footer />
            
            </motion.div>
        </div>
    )
}