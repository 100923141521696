
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../static-features/Footer";
import DownloadButton from "../images/downloadButton.png"

export default function()

{
    document.body.style.background = 'rgb(240,76,47)';
    return(
        <div className="home-page">
            < motion.div 
            initial={{opacity:0}}
            animate={{opacity:1}} >
      
            <div className='home-page-content-container'>
               
      
                <h1 className='home-page-heading'> SOUTH BERMONDSEY FESTIVAL</h1>
                <h2 className='home-page-subheading'> 26th & 27th August 2023</h2>
                <p className='home-page-para1'> A community-focused weekend of art, music & workshops taking place in South Bermondsey. 
                    SBF 2023 will take place across 4 key locations/venues: Ormside Projects, Venue MOT, Bridgehouse Gardens, and Avalon Cafe.
                    </p>
                
                <p className='home-page-para2'> You are not required to purchase a ticket for workshops taking place on Saturday and Sunday daytime. 
                    Tickets for Music on Sunday night can be purchased on Resident Advisor.</p>

                <a target="_blank" href="https://drive.google.com/drive/folders/11o6d58e8b_CVhV_TsRMBp6N7BzkpHNfz?usp=drive_link"> <img src={DownloadButton} className="download-button"/></a>


            </div>


            <Footer />
            </motion.div>
        </div>

       
    )
}