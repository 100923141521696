import React from "react";
import ProgrammeListing from "./ListingStructure2";
import Ticketed from '../images/ticketed.png'

export default function MusicListings()
{
    return(
        <div className="music-listing-container">
           
           
           <a href="https://ra.co/events/1741255" target="_blank">
           <img className="ticketed-sticker" src={Ticketed}/>
           </a>
            
            <a href="https://www.instagram.com/ormsideprojects/" target="_blank">
            <ProgrammeListing
            venue="ORMSIDE PROJECTS"
            datetime="Sunday | 21:00 - 06:00"
            artist1="James Massiah"
            artist2="Elheist (Live)"
            artist3="Jon K"
            artist4="Tash LC"
            artist5="HITMAKERCHINX"
            artist6="Sarra Wild"
            artist7="Nkisi"
            artist8="Living Gatlato"

            slot1="21:00 - 22:30"
            slot2="22:30 - 23:00"
            slot3="23:00 - 00:15"
            slot4="00:15 - 01:30"
            slot5="01:30 - 02:15"
            slot6="02:15 - 03:30"
            slot7="03:30 - 05:00"
            slot8="05:00 - 06:00"
            />
            </a>
            
          
            <a href="https://www.instagram.com/avaloncafebermondsey/" target="_blank">

            <ProgrammeListing 
            venue="AVALON CAFE"
            datetime="Sunday | 18:00 - 06:00"
            artist1="Me, Charles"
            artist2="Teeth Machine"
            artist3="feeo"
            artist4="FLywheel"
            artist5="Limecrate"
            artist6="Lagoon Femshayma"
            artist7="Wes Baggaley"
            artist8="Paul"
            artist13="Adam Christensen + Vindicatrix"


            slot1="19:00 - 20:00"
            slot2="20:00 - 21:00"
            slot3="21:00 - 22:00"
            slot4="22:00 - 22:45"
            slot5="22:45 - 00:30"
            slot6="00:30 - 02:00"
            slot7="02:00 - 04:00"
            slot8="04:00 - 05:00"
            slot13="05:00 - 06:00"


            />

            </a>

            <a href="https://www.instagram.com/venuemot/" target="_blank">
            <ProgrammeListing 
            venue="VENUE MOT"
            venue1="UNIT 18"
            venue2="UNIT 20"
            datetime="Sunday | 21:00 - 07:00"
            artist1="Cliché Toupee (Live)"
            artist2="Avel Mismo (Live)"
            artist3="ddwy (Live) "
            artist4="Baron"
            artist5="Silvia Kastel"
            artist6="EL-B"
            artist7="Stem"
            artist8="Anina"
            artist9="Elle Andrews"
            artist10="DJ Lycox"
            artist11="Scratchclart"
            artist12="DJ Swisha"
            

            slot1="21:00 - 21:30"
            slot2="21:30 - 22:00"
            slot3="22:00 - 23:00"
            slot4="23:00 - 00:30"
            slot5="00:30 - 02:00"
            slot6="02:00 - 03:30"
            slot7="03:30 - 05:00"
            slot8="05:00 - 07:00"
            slot9="00:00 - 01:30"
            slot10="01:30 - 03:00"
            slot11="03:00 - 04:30"
            slot12="04:30 - 06:00"
            />

            </a>
           


       </div>
       
    )
}