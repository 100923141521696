import InstaLogo from "./footer-icons/instagram.png"
import RALogo from "./footer-icons/ra.png"
import ArtsLogo from "./footer-icons/artscouncil.png"


export default function Footer()
{


   return(
      <div className="footer-container">
         <footer className="footer-logos" >
         <a className="logo-group" href="https://www.instagram.com/southbermondseyfestival/" target="_blank" >
            <img className="insta-logo" src={InstaLogo}/>
         </a>

         <a>
            <img className="arts-logo" src={ArtsLogo}/>
         </a> 

         <a className="logo-group" href="https://ra.co/events/1741255" target="_blank"> 
            <img className="ra-logo" src={RALogo}/>
         </a>

         
      </footer>
         
      </div>
      

   )
   
}