import { BrowserRouter,Route, Link, useLocation} from 'react-router-dom';
import Logo from "../images/sbflogo-nav.png"

export default function Navbar()
{
    return(
        <nav className='nav-bar'>
            <ul className="nav-content">
                <li> <img className='sbf-logo' src={Logo}/></li>
                <li> <Link to={"/Home"}> HOME </Link></li>
        
                <li> <Link to={"/Programme"}> WHAT'S ON </Link></li>
                <li> <Link to="https://ra.co/events/1741255" target="_blank"> TICKETS</Link></li>
                <li> <Link to={"/Getting Here"}>GETTING HERE</Link></li>

            </ul>

        </nav>

            
       
    )
}