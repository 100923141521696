import React, {useState} from "react";
import MusicListings from "./MusicListings";

import WorkshopArt from "./WorkshopListings";

import Footer from "../static-features/Footer";

import WorkshopText from "../images/workshops.png"
import MusicText from "../images/music.png"

export default function InteractiveMap()
{
    const [lineup1, lineup1_setsShown] = useState(true);
    const [lineup2, lineup2_setsShown] = useState(false);

    const workshop = () => {
        lineup1_setsShown(true)
        lineup2_setsShown(false)
       
        document.body.style.background = 'rgb(240,76,47)';
        
    }

    const music = () => {
        lineup1_setsShown(false)
        lineup2_setsShown(true)
        document.body.style.background = 'black';
    }

  

    return(
        <div className="home-container">
            
                <img className="workshop-text" src={WorkshopText} onClick={workshop} />
                <img className="music-text" src={MusicText} onClick={music}/>

    
                
            {lineup1 && (
            <div className="workshop-content-container">
                <WorkshopArt />
            </div>
           
            )}
           
    
            {lineup2 && (
                <div className="music-content-container">
                    <MusicListings />
                </div>
            )}



            <Footer />

        
        </div>
        

    )

      

}

