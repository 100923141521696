import React from "react";
import Listing from "./ListingStructure";
import Unticketed from '../images/unticketed.png'

export default function WorkshopArt()
{
    return(
        <div className="workshop-listings-container">

            <h1 className="page-title"> WORKSHOPS, ART WORKS AND ACTIVITIES</h1>
        
            <img src={Unticketed} className="unticketed-sticker"/> 

            <Listing 
            number="14."
            name="Sound Opus: Sounding the Neighborhood by Shepherd Manyika"
            time="Saturday | 11:00 - 13:00"
            description="Listening session of a collaborative playlist created with the community
            during pre festival workshop."
            location="[Location: Bridgehouse Gardens]"
            />

            <Listing 
            number="7."
            name="Guerilla picnic: Led by Calcio"
            time="Saturday | 11:00 - 18:00"
            description="The workshop will involve painting picnic benches and parasols that will be
            carried to the Bridgehouse Meadow the following day in the form of a Parade.
            led by Calcio"
            location="[Location: 16-28 penarth st]"
            />       

            <Listing 
            number="3."
            name="That's Nuts"
            time="Saturday | 12:00 - 18:00"
            description="A three person exhibition and art market with
            RAN Studios x Yangdzom Lama, Serafina Mbasogo Mbala & Lizzie Farrell"
            location="[Location: RAN Studios Unit 15, Orion Business Centre, SE14 5RT]"
            />

<Listing 
            number="8."
            name="Grain - Sound + Improvisation workshop"
            time="Saturday | 12:00 - 14:00"
            description="Explore and discuss ideas surrounding improvisation, group interplay, texture,
            sound and experimental approaches to composition.
            Using traditional instruments and found/re-purposed objects, voice and
            graphic score"
            location="[Location: Avalon Cafe]"
            />
 
            <Listing 
            number="9."
            name="Weaving with Nature workshop with Sara Kelly"
            time="Saturday | 12:00 - 16:00"
            description="join for a weaving workshop on an upright frame, using foraged and found
            materials around the site. suitable for all ages!"
            location="[Location: Bridgehouse Gardens]"
            />


            <Listing 
            number="11."
            name="Against The Run of Play: Any Pitch Way"
            time="Saturday | 12:00 - 18:00 (free coaching 14:45 & 16:00)"
            description="Bridgehouse Meadows is being transformed into a space for playing together.
            A.T.R.O.P have invited 4
            artists to collectively create a pop up football pitch.
            Open to all! Two free training sessions led by football coach Carla Recover will run during the day. TICKET LINKS HERE:
           "
            link1="14:45 (7-13 years old only)"
            link2="16:00 (Open to all)"
            location="[Location: Bridgehouse Meadows]"
            />

            <Listing 
            number="12."
            name="Film Viewing and Launch of Bridgehouse Community Visual
            Archive, by Artur Siudem"
            time="Saturday | 12:00 - 16:00"
            description="Bridgehouse Community Visual Archive is a multimodal project aimed to document the development of Bridgehouse Gardens and to record stories from residents of Winslade Estate in the face of rapidly changing urban environment. Come to find out more and have a chat with us!"
            location="[Location: Bridgehouse Gardens]"
            />

            <Listing 
            number="10."
            name="Green seasoning - Workshop with Zephy Polius"
            time="Saturday | 14:00 - 16:00"
            description="Pick herbs from the garden and make green seasoning! Open to all!"
            location="[Location: Bridgehouse Gardens]"
            />

            <Listing 
            number="15."
            name="Winslade Carnival Day with Purposely United"
            time="Saturday | 14:00 - 20:00"
            description="Bridgehouse Gardens in collaboration with Purposely United invite everyone for an afternoon of food, free activities and music. Bouncy castle, face painting, mask making, Dominican dance, food and more. Join us!"
            location="[Location: Bridgehouse Gardens]"
            />

            <Listing 
            number="13."
            name="Two Way Dreaming - Fugitive Film Club"
            time="Saturday | 14:30 - 16:00"
            description="(Title Withheld, 1980) In the 1970s and early 1980s Detroit was the setting 
            for an unusual development in U.S. urban politics, as voters elected two socialists to citywide office. The film examines these 
            people and their organization against the backdrop of a city in extreme economic crisis. Intoroduced by Louis Moreno
            "
            location="[Location: Avalon Cafe]"
            />

        
            <Listing 
            number="17."
            name="Ormside x Montez Press Radio: 6hr live broadcast"
            time="Saturday | 12:00 - 18:00"
            description="Ft: Grace, Enya, The Skins, Tinai Zivengwa, Josef Kurtz, Em-Dash. Come listen in person or @ "
            link3="montezpress.com"
            location="[Location: Montez Press Studio, Penarth Centre]"
            />

<Listing 
            number="16."
            name="Life Is Beautiful"
            time="Saturday | 18:00 - 19:00 "
            description="Life is Beautiful present a live improvisation of music and movement featuring:
            Isaiah Hull, Jaso, Abi Asisa, Bianca Scout, Samara Langham and Aloisius"
            location="[Location: Ormside Projects & London Performance Studios Penarth St, SE15 1TR]"
            />



            <Listing 
            number="1."
            name="Barnaby Lewis - Angel Orion"
            time="Saturday & Sunday "
            description="Furniture maker based on Surrey canal road mostly specialising in
            contemporary black smithing. Makes a sculpture to grace the road on which
            they spend most of their days."
            location="[Location: Left of Orion Business Park]"
            />
            <Listing 
            number="2."
            name="Nathan Bather - Mercury Glass, Orion Glow / projections"
            time="Saturday & Sunday (after sundown)"
            description="Re-welding, re-mapping, re-wilding the concrete riviera. In this projected work,
            fragments of Surrey Canal Road will be collapsed and re-birthed into a new
            natural habitat across multiple locations."
            location="[Location: Bridges of Surrey Canal Rd and SELCHP Chimney]"
            />

        
            <Listing 
            number="4."
            name="Celeste Anstruther - Underside"
            time="Saturday & Sunday"
            description="assemblage sculptures and images using waste materials collected from street
            walking. The prints are like cameraless maps, made from a sensory archive of
            scraps."
            location="[Location: Opposite Avalon Cafe]"
            />

            <Listing 
            number="5."
            name="Ricardo Monteiro - Burrow (rest your little legs, little mouse)"
            time="Saturday & Sunday"
            description="Installed in Avalons Garden"
            location="[Location: Avalon Cafe Garden]"
            />

            <Listing 
            number="6."
            name="Gabrielle Jameson - Lightbox"
            time="Saturday & Sunday"
            description="Enjoy the unknown & unseen parts of the world.
            installed outside my studio, the lightbox is a vessel or looking glass for you to
            peer into the simplicities of our world."
            location="[location: Penarth Street]"
            />

            <Listing 
            number=""
            name="Sound Opus: Sounding the Neighborhood by Shepherd Manyika"
            time="Sunday | 10:00 - 12:00"
            description="Listening session of a collaborative playlist created with the community
            during pre festival workshop."
            location="[Location: Bridgehouse Gardens]"
            />

            <Listing 
            number=""
            name="Foraging walk with Project Common Space"
            time="Sunday | 11:00 - 13:00"
            description="Fuck Big Shops, Feed Yourself From The Block', a beginner's foraging walk with Project Common Space
            Join us for a foraging walk across the Festival locations, and discover the abundance growing in this concrete riviera!
            "
            location="[Location: Meet at Bridgehouse Gardens]"
            />

            <Listing 
            number=""
            name="Guerilla picnic: Parade / Performance with Calcio "
            time="Sunday | 12:00 - 13:00"
            description="We will celebrate the installation of the benches with a journey from Penarth Street to the Meadows. In the form of a parade, it will include music and performance. Everyone welcome!"
            location="[Location: Meet at Bridgehouse Gardens, ends at
            Bridgehouse Meadows where we'll join the Guerilla picnic with Calcio]"
            />

            <Listing 
            number=""
            name="Guerilla picnic: Picnic / Celebration with Calcio
            "
            time="Sunday | 13:00 - 15:00"
            description="Food and drinks for everyone in the local area and those who took part in the workshop to celebrate the community and say thank you for taking part. Everyone welcome!"
            location="[Location: Bridgehouse Meadows]"
            />

           
    


        </div>

    )
}