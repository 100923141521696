import React from "react";

export default function Listing(props)
{
    return(
        <div className="home-listings-container" > 
            <ul className="home-page-listings">
            <li className="listing-number-name">{props.name}</li>
            <li className="listing-time"> {props.time} </li>
            <li className="listing-description"> {props.description} </li>
            
            <li> <a href="https://www.eventbrite.com/e/against-the-run-of-play-any-pitch-way-free-training-session-age-7-13-tickets-703149728767?aff=oddtdtcreator"
               className="active-link"
               target="_blank"> 
             {props.link1} </a> </li>

            <li> <a href=" https://www.eventbrite.com/e/against-the-run-of-play-any-pitch-way-free-coaching-session-all-ages-tickets-703177050487?aff=oddtdtcreator"
               className="active-link"
               target="_blank">
               {props.link2}
            </a>   </li>

            <li> <a href="https://radio.montezpress.com/#/live/"
                    className="active-link"
                    target="_blank">
                    {props.link3}</a></li>

           
            <li className="listing-location"> {props.location} </li>

            </ul>     

          
        </div>
    )
}

