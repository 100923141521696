import React from "react";
import { BrowserRouter, HashRouter} from 'react-router-dom';
import Layout from "./components/static-features/Layout";

import AnimatedRoutes from "./components/static-features/AnimatedRoutes";


export default function App()
{
  return(
    <div className="App">
      <HashRouter>
      <AnimatedRoutes />
      </HashRouter>
    </div>
  
  )
}