import InteractiveMap from "../listing/InteractiveMap";
import { motion, AnimatePresence } from "framer-motion";



export default function Programme()
{
    document.body.style.background = 'rgb(240,76,47)';
    return(
        
        <div>
           

             <div className="programme-page">

            <motion.div 
            initial={{opacity:0}}
            animate={{opacity:1}}
            >
           
            < InteractiveMap />
            </motion.div>
            
        </div>
        </div>
       
    )
}