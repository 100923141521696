import React from "react";
import Home from "../pages/Home";
import Programme from "../pages/Programme";
import Layout from "./Layout";
import GettingHere from "../pages/GettingHere";


import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";


export default function AnimatedRoutes()
{
    const location = useLocation();
    return(

        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Layout/>}> 
                <Route index element={<Home/>}/>
        
                <Route path="Programme" element={<Programme/>}/>
                <Route path="Home" element={<Home/>}/>
                <Route path="Getting Here" element={<GettingHere/>}/>
                
               
                </Route>
            </Routes>

        </AnimatePresence>
       
      
    )
}