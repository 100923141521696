import React from "react"

export default function ProgrammeListing(props)
{
    return(
        <div>
            <ul className="home-page-listings-2" >
                <li className="listing-venue">{props.venue}</li>
                <li className="listing-date-time"> {props.datetime}</li>
                <li className="unit">{props.venue1}</li>
                <li className="listing-artist-names"> 
                    <ul className="artist-names">
                        <li>{props.artist1}</li>
                        <li className="slot-times">{props.slot1}</li>
                        <li>{props.artist2}</li>
                        <li className="slot-times">{props.slot2}</li>
                        <li>{props.artist3}</li>
                        <li className="slot-times">{props.slot3}</li>
                        <li>{props.artist4}</li>
                        <li className="slot-times">{props.slot4}</li>
                        <li>{props.artist5}</li>
                        <li className="slot-times">{props.slot5}</li>
                        <li>{props.artist6}</li>
                        <li className="slot-times">{props.slot6}</li>
                        <li>{props.artist7}</li>
                        <li className="slot-times">{props.slot7}</li>
                        <li>{props.artist8}</li>
                        <li className="slot-times">{props.slot8}</li>
                        <li>{props.artist13}</li>
                        <li className="slot-times">{props.slot13}</li>
                    </ul>
                </li>
                <li className="unit"> {props.venue2}</li>
                <li>
                    <ul className="artist-names">
                   
                        <li>{props.artist9}</li>
                        <li className="slot-times">{props.slot9}</li>
                        <li>{props.artist10}</li>
                        <li className="slot-times">{props.slot10}</li>
                        <li>{props.artist11}</li>
                        <li className="slot-times">{props.slot11}</li>
                        <li>{props.artist12}</li>
                        <li className="slot-times">{props.slot12}</li>
                    </ul>
                </li>
                

               

            </ul>
        </div>
    )
}